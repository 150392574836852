<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<nz-layout class="landing-angle">
   <nz-layout>
      <section class="header">
         <div class="angle-id">
            <i
               nz-icon
               nzType="arrow-left"
               nzTheme="outline"
               (click)="backClicked()"></i>
            <span>
               <app-beta
                  [angleLanding]="true"
                  [showBInMobile]="true"
                  [margin]="betaMargin"></app-beta>
               Angle ID #{{ angleId }}
            </span>
         </div>
         <div class="angle-icon-title-flex">
            <div class="angle-icon-title-split">
               <span
                  class="eye-invisible-icon"
                  *ngIf="angleDetails.privacy">
                  <span
                     nz-icon
                     nzType="eye-invisible"
                     nzTheme="outline"></span>
               </span>
            </div>
            <div class="angle-icon-title-split">
               <div class="angle-title">
                  {{ angleDetails.angleTitle }}
               </div>
            </div>
         </div>

         <div class="linked-story">
            <span class="title">Linked Story:</span>
            <span class="linked-story-names">
               <span
                  class="details"
                  *ngFor="let relatedStory of relatedStories; let first = first">
                  <span *ngIf="!first">,</span>
                  <a
                     class="details"
                     href="/#/ncx/landing-story/:{{ relatedStory.storyId }}">
                     {{ relatedStory.storyTitle }}
                  </a>
               </span>
            </span>
         </div>

         <div class="story-team">
            <span class="title">Collaborators:</span>
            <span class="story-team-members">
               <ng-container *ngFor="let collaborator of collaboratorsDetails; let first = first">
                  <span
                     class="details"
                     *ngIf="!first">
                     ,
                  </span>
                  <button
                     class="details"
                     nz-popover
                     [nzPopoverContent]="profileCardTemplate"
                     [nzPopoverPlacement]="'bottomLeft'">
                     {{ collaborator.fullName }}
                  </button>
                  <ng-template #profileCardTemplate>
                     <app-profile-overlay [profileData]="collaborator"></app-profile-overlay>
                  </ng-template>
               </ng-container>
            </span>
         </div>
      </section>
      <nz-content class="angle-tabs">
         <!-- Body Content Start -->
         <ng-container>
            <nz-tabset
               [(nzSelectedIndex)]="tabMenuSelection"
               (nzSelectedIndexChange)="tabMenuChanges($event)">
               <!-- ANGLE DESCRIPTION TAB -->
               <nz-tab
                  nzTitle="Overview"
                  [nzDisabled]="!editAccess && angleDetails.privacy">
                  <div
                     *ngIf="tabMenuSelection === 0"
                     class="angle-body body-margin"
                     #bodyElement>
                     <ng-container *ngTemplateOutlet="overviewTpl"></ng-container>
                  </div>
               </nz-tab>

               <!-- ANGLE POSTS TAB -->
               <nz-tab
                  nzTitle="Material"
                  [nzDisabled]="!editAccess && angleDetails.privacy">
                  <div
                     *ngIf="tabMenuSelection === 1"
                     class="angle-body body-margin"
                     #bodyElement>
                     <ng-container *ngTemplateOutlet="materialTpl"></ng-container>
                  </div>
               </nz-tab>

               <!-- ANGLE LOG TAB -->
               <nz-tab
                  nzTitle="Log"
                  [nzDisabled]="!editAccess && angleDetails.privacy">
                  <div
                     *ngIf="tabMenuSelection === 2"
                     class="angle-body"
                     #bodyElement>
                     <ng-container *ngTemplateOutlet="logTpl"></ng-container>
                  </div>
               </nz-tab>
            </nz-tabset>
         </ng-container>
         <!-- Body Content End-->
      </nz-content>

      <nz-footer
         *ngIf="showFooter"
         class="site-page-footer">
         <button
            *ngIf="deleteAccess"
            class="delete-btn"
            nz-button
            [nzShape]="!isMobile ? 'round' : 'circle'"
            nzType="danger"
            (click)="showConfirmModal()">
            <i
               nz-icon
               nzType="delete"
               nzTheme="outline"></i>
            <span *ngIf="!isMobile">Delete</span>
         </button>
         <button
            *ngIf="editAccess"
            class="add-new-element-btn"
            nz-button
            [nzShape]="!isMobile ? 'round' : 'circle'"
            nzType="default"
            (click)="addNewElement()">
            <i
               nz-icon
               nzType="plus-circle"
               nzTheme="outline"></i>
            <span *ngIf="!isMobile">Add New</span>
         </button>
         <button
            nz-button
            nzShape="round"
            nzType="primary"
            id="test"
            *ngIf="editAccess"
            (click)="editAngle()">
            <i
               nz-icon
               nzType="edit"
               nzTheme="outline"></i>
            Edit
         </button>
      </nz-footer>

      <ng-template #overviewTpl>
         <app-scroll-container
            *ngIf="viewAccess"
            [footerHeight]="footerHeight"
            [additionalPadding]="24">
            <ng-template bodyTemplate>
               <div
                  class="overview"
                  [innerHtml]="overviewContent | safeHtml"></div>
            </ng-template>
         </app-scroll-container>

         <div
            class="overview-private center-empty-icon"
            #tabContent
            *ngIf="!viewAccess">
            <nz-empty [nzNotFoundContent]="contentTpl">
               <ng-template #contentTpl>
                  <span>
                     This angle is private. Reach out to the listed collaborators if you need access to view or edit
                  </span>
               </ng-template>
            </nz-empty>
         </div>
      </ng-template>

      <ng-template #materialTpl>
         <div class="angle-material-header">
            <div class="filter-post-lg mb-16">
               <ng-container *ngTemplateOutlet="postBtnGroup"></ng-container>
               <ng-container *ngTemplateOutlet="searchFilter"></ng-container>
               <ng-container *ngTemplateOutlet="filterBtn"></ng-container>
            </div>
            <div class="filter-post-xs mb-16">
               <ng-container *ngTemplateOutlet="postBtnGroup"></ng-container>
               <div class="inline-block search-filter">
                  <ng-container *ngTemplateOutlet="searchFilter"></ng-container>
                  <ng-container *ngTemplateOutlet="filterBtn"></ng-container>
               </div>
            </div>
         </div>

         <ng-template #postBtnGroup>
            <div class="post-btn-group">
               <nz-segmented
                  [nzOptions]="options"
                  [nzBlock]="isMobile ? true : false"
                  [(ngModel)]="activeTab"
                  (nzValueChange)="materialTabChange($event)"></nz-segmented>
               <!-- <button
                  nz-button
                  [ngClass]="{ secondary: true, active: materialSelectedTab === 'all' }"
                  (click)="materialTabChange('all')"
                  [disabled]="!isMaterialAPILoaded">
                  <i
                     nz-icon
                     nzType="unordered-list"
                     nzTheme="outline"></i>
                  All
               </button>
               <button
                  nz-button
                  [ngClass]="{ secondary: true, active: materialSelectedTab === 'elements' }"
                  (click)="materialTabChange('elements')"
                  [disabled]="!isMaterialAPILoaded">
                  <i
                     nz-icon
                     nzType="file-image"
                     nzTheme="outline"></i>
                  Elements
               </button>
               <button
                  nz-button
                  [ngClass]="{ secondary: true, active: materialSelectedTab === 'standards' }"
                  (click)="materialTabChange('standards')"
                  [disabled]="!isMaterialAPILoaded">
                  <i
                     nz-icon
                     nzType="audit"
                     nzTheme="outline"></i>
                  Standards
               </button> -->
            </div>
         </ng-template>

         <ng-template #searchFilter>
            <div class="post-search-form">
               <form [formGroup]="postSearchForm">
                  <nz-input-group
                     [nzSuffix]="suffixIconSearch"
                     class="search-post"
                     [class.collapsed]="filterDrawerOpen">
                     <input
                        #searchText
                        type="text"
                        nz-input
                        placeholder="Search"
                        formControlName="searchBox"
                        (keyup.enter)="searchMaterialPost()" />
                  </nz-input-group>
                  <ng-template #suffixIconSearch>
                     <i
                        class="cursor-pointer"
                        nz-icon
                        [attr.title]="!!postSearchForm.controls['searchBox'].value ? 'Clear' : 'Search'"
                        [class.clear-search]="!!postSearchForm.controls['searchBox'].value"
                        [nzType]="!!postSearchForm.controls['searchBox'].value ? 'close-circle' : 'search'"
                        (click)="
                           !!postSearchForm.controls['searchBox'].value ? clearMaterialSearch() : searchMaterialPost()
                        "></i>
                  </ng-template>
               </form>
            </div>
         </ng-template>

         <ng-template #filterBtn>
            <button
               nz-button
               class="filters"
               (click)="toggleFilterDrawer()">
               <i
                  *ngIf="!isFilterApply"
                  [class.blue-outline]="filterDrawerOpen"
                  nz-icon
                  nzType="filter"
                  nzTheme="outline"></i>
               <i
                  *ngIf="isFilterApply"
                  nz-icon>
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="20"
                     height="20"
                     viewBox="0 0 20 20"
                     fill="none">
                     <path
                        d="M7.43746 16.4922C7.37291 16.4922 7.31636 16.4398 7.31636 16.3672V13.0391H12.6835V16.3672C12.6835 16.4398 12.627 16.4922 12.5625 16.4922H7.43746ZM2.8105 3.50781H17.1894C17.2775 3.50781 17.3447 3.60849 17.2969 3.69621L13.1223 10.7891H6.88155L2.70573 3.69413C2.65704 3.60794 2.72164 3.50781 2.8105 3.50781Z"
                        fill="#1890FF"
                        stroke="#1890FF" />
                  </svg>
               </i>
            </button>
         </ng-template>
         <app-scroll-container
            [footerHeight]="footerHeight"
            [additionalPadding]="24">
            <ng-template bodyTemplate>
               <div
                  infiniteScroll
                  [infiniteScrollDistance]="2"
                  [infiniteScrollThrottle]="50"
                  (scrolled)="onScrollDown()"
                  [scrollWindow]="false">
                  <app-angle-material
                     [angleId]="angleId"
                     [scrollDownEvent]="scrollDownSubject.asObservable()"
                     [materialSelectedTab]="materialSelectedTab"
                     [postSearchForm]="postSearchForm"
                     [postFilterForm]="postFilterForm"
                     (isAPILoadedEvent)="materialAPILoad($event)"></app-angle-material>
               </div>
            </ng-template>
         </app-scroll-container>
      </ng-template>

      <ng-template #logTpl>
         <app-scroll-container
            [footerHeight]="footerHeight"
            [additionalPadding]="24">
            <ng-template bodyTemplate>
               <div
                  infiniteScroll
                  [infiniteScrollDistance]="2"
                  [infiniteScrollThrottle]="50"
                  (scrolled)="onScrollDown()"
                  [scrollWindow]="false">
                  <div class="body-margin">
                     <app-angle-log
                        [angleId]="angleId"
                        [scrollDownEvent]="scrollDownSubject.asObservable()"></app-angle-log>
                  </div>
               </div>
            </ng-template>
         </app-scroll-container>
      </ng-template>
   </nz-layout>

   <nz-sider
      *ngIf="!isMobile && filterDrawerOpen"
      nzCollapsible
      [nzWidth]="300"
      [nzTrigger]="null"
      nzCollapsedWidth="0">
      <div class="right-sider">
         <ng-container [ngTemplateOutlet]="siderContent"></ng-container>
      </div>
   </nz-sider>
</nz-layout>

<nz-drawer
   *ngIf="isMobile && filterDrawerOpen"
   [nzClosable]="false"
   [nzVisible]="filterDrawerOpen"
   [nzWrapClassName]="'no-padding'"
   [nzMaskClosable]="true"
   nzPlacement="right"
   [nzWidth]="320"
   (nzOnClose)="toggleFilterDrawer()">
   <ng-container *nzDrawerContent>
      <div class="right-sider filters-mobile">
         <ng-container [ngTemplateOutlet]="siderContent"></ng-container>
      </div>
   </ng-container>
</nz-drawer>

<ng-template #siderContent>
   <div class="sider-close">
      <span>Filters</span>
      <i
         nz-icon
         nzType="close"
         nzTheme="outline"
         (click)="toggleFilterDrawer()"></i>
   </div>
   <app-scroll-container [sideBarContainer]="true">
      <ng-template bodyTemplate>
         <div class="sider-content">
            <form [formGroup]="postFilterForm">
               <nz-collapse
                  nzExpandIconPosition="right"
                  nzBordered="false"
                  class="mb-20">
                  <nz-collapse-panel
                     nzHeader="Editorial/Standards"
                     nzActive="true"
                     class="elements">
                     <ul class="editor-standard">
                        <li *ngFor="let item of filterEditorial; let last = last">
                           <label
                              nz-checkbox
                              [class.mb-8]="!last"
                              [formControlName]="item">
                              {{ filterEditorialObj[item] }}
                           </label>
                        </li>
                     </ul>
                  </nz-collapse-panel>
               </nz-collapse>
               <nz-collapse
                  nzExpandIconPosition="right"
                  nzBordered="false"
                  class="mb-20">
                  <nz-collapse-panel
                     nzHeader="R&C Legal"
                     nzActive="true"
                     class="elements">
                     <ul class="editor-standard">
                        <li *ngFor="let item of filterLegal; let last = last">
                           <label
                              nz-checkbox
                              [class.mb-8]="!last"
                              [formControlName]="item">
                              {{ filterLegalObj[item] }}
                           </label>
                        </li>
                     </ul>
                  </nz-collapse-panel>
               </nz-collapse>
               <div class="mb-20"></div>
               <nz-collapse
                  nzExpandIconPosition="right"
                  nzBordered="false"
                  class="mb-20">
                  <nz-collapse-panel
                     [nzHeader]="sortByTpl"
                     nzActive="true"
                     class="elements">
                     <nz-radio-group
                        class="sort-by"
                        formControlName="sortBy">
                        <ng-container *ngFor="let item of sortBy; let last = last">
                           <label
                              nz-radio
                              [class.mb-8]="!last"
                              [nzValue]="item">
                              {{ sortByObj[item] }}
                           </label>
                        </ng-container>
                     </nz-radio-group>
                     <ng-template #sortByTpl>
                        <span class="filter-collapse-header">
                           <i
                              nz-icon
                              nzType="sort-ascending"
                              nzTheme="outline"></i>
                           Sort By
                        </span>
                     </ng-template>
                  </nz-collapse-panel>
               </nz-collapse>
               <div class="mb-20"></div>
               <nz-collapse
                  nzExpandIconPosition="right"
                  nzBordered="false"
                  class="mb-20">
                  <nz-collapse-panel
                     [nzHeader]="dateTpl"
                     nzActive="true"
                     class="elements">
                     <nz-range-picker
                        [nzDateRender]="tplRender"
                        formControlName="date"></nz-range-picker>
                     <ng-template
                        #tplRender
                        let-current>
                        <div
                           class="ant-picker-cell-inner"
                           [class.border]="current.getDate() === 1">
                           {{ current.getDate() }}
                        </div>
                     </ng-template>
                     <ng-template #dateTpl>
                        <span class="filter-collapse-header">
                           <i
                              nz-icon
                              nzType="calendar"
                              nzTheme="outline"></i>
                           Date
                        </span>
                     </ng-template>
                  </nz-collapse-panel>
               </nz-collapse>

               <button
                  class="mb-20 clear-btn"
                  nz-button
                  nzBlock
                  (click)="clearMaterialFilter()">
                  <span
                     nz-icon
                     nzType="clear"
                     nzTheme="outline"></span>
                  Clear
               </button>
            </form>
         </div>
      </ng-template>
   </app-scroll-container>
</ng-template>
