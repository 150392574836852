<nz-collapse
   nzExpandIconPosition="right"
   nzBordered="false">
   <nz-collapse-panel
      nzHeader="Linked Stories"
      nzActive="true">
      <div
         *ngFor="let story of relatedStoryTitles"
         class="story-flex">
         <a
            href="/#/ncx/landing-story/:{{ story.storyId }}"
            class="story-text"
            (click)="redirectTo(story.storyId)"
            [innerHTML]="story.storyTitle | safeHtml"></a>
      </div>
   </nz-collapse-panel>
</nz-collapse>
