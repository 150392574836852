import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FollowStoryUpdateEvent } from '@models/ncx/followers';
import { Stories, StoriesPost } from '@models/ncx/story';
import { Common } from '@utilities/common';
import { Time } from '@utilities/time';

@Component({
  selector: 'ncx-story',
  templateUrl: './story.component.html',
  styleUrls: ['../cards-common.scss', './story.component.scss']
})
export class StoryComponent {

  @Input() story: Stories = {} as Stories;

  // Show only story information (no Post)
  // If set to 'true' it's assumed this is a "pure Story" card and
  // will show only the Story Title and person who originally created the story.
  @Input() storyOnly: boolean = false;

  @Input() showPostTitle: boolean = true;
  @Input() showPostDescription: boolean = true;
  @Input() showPostAttachments: boolean = true;
  @Input() showPostTimeAgo: boolean = false;
  @Input() showPostLabels: boolean = true;

  @Input() showFooter: boolean = true;
  @Input() showShare: boolean = true;
  @Input() showFollow: boolean = true;
  @Input() showNumComments: boolean = true;
  @Input() showNumAttachments: boolean = true;
  @Input() showNumTags: boolean = false;

  // Index of story among other stories
  @Input() index: number = 0;

  @Output() followerUpdated: EventEmitter<FollowStoryUpdateEvent> = new EventEmitter<FollowStoryUpdateEvent>();
  @Output() contentShared: EventEmitter<Stories> = new EventEmitter<Stories>();

  showShareModal: boolean = false;

  public readonly Common = Common;
  userId: number;

  constructor() {
    this.userId = Number(localStorage.getItem('userId'));
  }

  calculateDiff(date): string {

    return Time.calculateDiff(date);

  }

  utcToLocal(apiDate, type) {

    if (type === 'DATE') {
      return Time.convertingUtcToLocalDate(apiDate);
    } else {
      return Time.convertingUtcToLocalTime(apiDate);
    }

  }

  onFollow(status: FollowStoryUpdateEvent) {

    const { type: isFollowing, data } = status;

    this.story.isUserFollowing = isFollowing;
    this.story.storyFollowerCount = data.storyFollowerCount;

    this.followerUpdated.emit(status);

  }

  onShare() {

    this.showShareModal = true;

    this.contentShared.emit(this.story);

  }

  closeShareModal() {

    this.showShareModal = false;

  }

  get shareMessage(): string {

    return this.hasPost ? `Check out ${this.post.postTitle}` : '';

  }

  /**
   * Latest Post for this story
   *
   */
  get post(): StoriesPost {

    return Array.isArray(this.story.storyPosts) && this.story.storyPosts.length ? this.story.storyPosts[0] : {} as StoriesPost;

  }

  /**
   * Link for the Story
   *
   */
  get storyLink(): string {

    if (this.story.isGroupPost) {
      return `/#/group-details/:${this.story.storyId}`;
    } else {
      return `/#/ncx/landing-story/:${this.story.storyId}`;
    }

  }

  /**
   * Link for the Post
   *
   */
  get postLink(): string {
    if (this.story.isGroupPost) {
      return `/#/ncx/discussion-details?groupId=${this.story.storyId}&discussionId=${this.post.postId}`;
    } else {
      return `/#/ncx/view-post/:${this.post.postId}`;
    }
  }

  /**
   * Does the Story have a latest Post?
   *
   */
  get hasPost(): boolean {

    return Array.isArray(this.story.storyPosts) && this.story.storyPosts.length > 0;

  }

  /**
   * Does the Post have a description
   *
   */
  get hasDescription(): boolean {

    return !!this.post.postPlainContent?.trim();

  }

  /**
   * Does Post have Editorial or Legal labels
   *
   */
  get hasLabels(): boolean {

    return (Array.isArray(this.post.postLegal) && this.post.postLegal.length > 0) ||
      (Array.isArray(this.post.editorialStatus) && this.post.editorialStatus.length > 0);

  }

  formatTitle(title) {
    return title.replace(/&nbsp|&nbsp;/g, ' ');
  }

}
