import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SessionBannerComponent } from '@components/banners/session/session-banner.component';
import { GroupDiscussionComponent } from '@components/cards/group/discussion/group-discussion.component';
import { StoryComponent } from '@components/cards/story/story.component';
import { HTMLRichTextCommentsComponent } from '@components/comments/html-comments/html-comments.component';
import { TextCommentsComponent } from '@components/comments/text-comments/text-comments.component';
import { CustomSvgComponent } from '@components/custom-svg/custom-svg.component';
import { InfoAttachmentsComponent } from '@components/info/attachments/info-attachments.component';
import { InfoCommentsComponent } from '@components/info/comments/info-comments.component';
import { InfoPinnedComponent } from '@components/info/pinned/info-pinned.component';
import { InfoShareComponent } from '@components/info/share/info-share.component';
import { InfoTagsComponent } from '@components/info/tags/info-tags.component';
import { LoaderComponentComponent } from '@components/loader/loader-component.component';
import { LabelsComponent } from '@components/posts/labels/labels.component';
import { LimitedLicenseComponent } from '@components/posts/limited-license/limited-license.component';
import { TagsNewComponent } from '@components/stories/tags-new/tags-new.component';
import { AdditionalStoryComponent } from '@components/~legacy/additionalStory/additionalStory.component';
import { AdditionalTopicComponent } from '@components/~legacy/additionalTopic/additionalTopic.component';
import { EmbedlyLinksComponent } from '@components/~legacy/embedlyLinks/embedly.component';
import { SlugComponent } from '@components/~legacy/slug/slug.component';
import { TagsComponent } from '@components/~legacy/tags/tags.component';
import { BodyHeaderTemplateDirective, BodyTemplateDirective, FooterTemplateDirective, HeaderTemplateDirective, RightSideTemplateDirective, SubHeaderTemplateDirective } from '@directives/layout-directives';
import { AddLabelsComponent } from '@modals/ncx/add-labels/add-labels.component';
import { AddMembersComponent } from '@modals/ncx/add-members/add-members.component';
import { AddUsersComponent } from '@modals/ncx/add-users/add-users.component';
import { GoogleDocsComponent } from '@modals/ncx/google-docs/google-docs.component';
import { InfoCenterResearchComponent } from '@modals/ncx/infocenter-research/infocenter-research.component';
import { MoveDiscussionComponent } from '@modals/ncx/move-discussion/move-discussion.component';
import { MovePostModalComponent } from '@modals/ncx/move-post/move-post.component';
import { RolesModalComponent } from '@modals/ncx/roles-modal/roles-modal.component';
import { ShareDiscussionComponent } from '@modals/ncx/share/discussion/share-discussion.component';
import { SharePostComponent } from '@modals/ncx/share/post/share-post.component';
import { ViewVideoThumbnailsComponent } from '@ncx/view-video-thumbnails/view-video-thumbnails.component';
import { ByPassSecurityPipe } from '@pipes/sanitizehtml-pipe';
import { AppSingletonService } from '@services/app-singleton.service';
import { UtilityService } from '@services/utility.service';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { RenderAlertsComponent } from '../ncx/alerts/render-alerts/render-alerts.component';
import { LinkedAnglesComponent } from '../shared/components/linked-angles/linked-angles.component';
import { LinkedStoriesComponent } from '../shared/components/linked-stories/linked-stories.component';
import { LinkedStoryTeamComponent } from '../shared/components/linked-story-team/linked-story-team.component';
import { AdminComponent } from './components/admin/admin.component';
import { AttachmentsComponent } from './components/attachments/attachments.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LightBoxComponent } from './components/lightbox/lightbox.component';
import { LinkToAngleOrStoryComponent } from './components/link-to-angle-or-story/link-to-angle-or-story.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { ProfileOverlayComponent } from './components/profile-overlay/profile-overlay.component';
import { RteditorComponent } from './components/rteditor/rteditor.component';
import { ViewAttachmentsComponent } from './components/view-attachments/view-attachments.component';
import { ViewThumbnailsComponent } from './components/view-thumbnails/view-thumbnails.component';
import { DemoNgZorroAntdModule } from './modules/ng-zorro-antd.module';

// FORM INPUT COMPONENTS
import { AdditionalTopicNewComponent } from '@components/additional-topic-new/additional-topic-new.component';
import { AttachmentsNewComponent } from '@components/attachments-new/attachments-new.component';
import { FollowGroupComponent } from '@components/followers/follow-group/follow-group.component';
import { FollowStoryComponent } from '@components/followers/follow-story/follow-story.component';
import { LimitedLicenseNewComponent } from '@components/posts/limited-license-new/limited-license-new.component';
import { ReportableNewComponent } from '@components/reportable-new/reportable-new.component';
import { StoryStatusComponent } from '@components/stories/story-status/story-status.component';
import { ReportableComponent } from '@components/~legacy/reportable/reportable.component';
import { FormInputAsyncSelectComponent } from '@form-inputs/async-input-select/async-input-select.component';
import { FormAsyncInputComponent } from '@form-inputs/async-input/async-input.component';
import { FormAsyncSelectComponent } from '@form-inputs/async-select/async-select.component';
import { FormCheckboxComponent } from '@form-inputs/checkbox/checkbox.component';
import { FormDatePickerComponent } from '@form-inputs/date-picker/date-picker.component';
import { FormDateRangePickerComponent } from '@form-inputs/date-range-picker/date-range-picker.component';
import { FormInputNumberComponent } from '@form-inputs/input-number/input-number.component';
import { FormInputSelectComponent } from '@form-inputs/input-select/input-select.component';
import { FormInputComponent } from '@form-inputs/input/input.component';
import { FormSelectComponent } from '@form-inputs/select/select.component';
import { FormSwitchComponent } from '@form-inputs/switch/switch.component';
import { FormTextareaComponent } from '@form-inputs/textarea/textarea.component';
import { FormTimePickerComponent } from '@form-inputs/time-picker/time-picker.component';
import { NBCIcon } from '@icons/nbc-icon';
import { LimitedLicenseModalComponent } from '@modals/ncx/limited-license/limited-license.component';
import { ReportableModalComponent } from '@modals/ncx/reportable/reportable.component';
import { StandardsGuidanceModalComponent } from '@modals/ncx/standards-guidance/standards-guidance.component';
import { FileIcon } from '@pipes/file-icon';
import { UtcToLocalPipe } from '@pipes/utc-to-local.pipe';
import { ElementDetailsViewComponent } from './components/element-details-view/element-details-view.component';
import { LicenseLimitationsViewComponent } from './components/license-limitations-view/license-limitations-view.component';
import { LinkedAnglesViewComponent } from './components/linked-angles-view/linked-angles-view.component';
import { LinkedStoriesViewComponent } from './components/linked-stories-view/linked-stories-view.component';
import { MediaCentralDetailsViewComponent } from './components/media-central-details-view/media-central-details-view.component';
import { ReportableApproverViewComponent } from './components/reportable-approver-view/reportable-approver-view.component';
import { ScrollContainerComponent } from './components/scroll-container/scroll-container.component';
import { StandardGuidanceViewComponent } from './components/standard-guidance-view/standard-guidance-view.component';
import { DefaultOrderKeyvaluePipe } from './pipes/default-order-keyvalue.pipe';
 import { NgxDocViewerModule } from 'ngx-doc-viewer';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DemoNgZorroAntdModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    NgxDocViewerModule
  ],
  declarations: [
    RteditorComponent,
    SlugComponent,
    TagsComponent,
    TagsNewComponent,
    AttachmentsComponent,
    AttachmentsNewComponent,
    GoogleDocsComponent,
    ReportableComponent,
    ReportableNewComponent,
    AdditionalStoryComponent,
    ViewAttachmentsComponent,
    ViewThumbnailsComponent,
    LightBoxComponent,
    SharePostComponent,
    ShareDiscussionComponent,
    EmbedlyLinksComponent,
    InfoCenterResearchComponent,
    PrivacyComponent,
    AdminComponent,
    ProfileOverlayComponent,
    AddMembersComponent,
    MoveDiscussionComponent,
    RenderAlertsComponent,
    LoaderComponentComponent,
    AddUsersComponent,
    RolesModalComponent,
    AddLabelsComponent,
    ByPassSecurityPipe,
    LimitedLicenseComponent,
    LimitedLicenseNewComponent,
    AdditionalTopicComponent,
    AdditionalTopicNewComponent,
    ViewVideoThumbnailsComponent,
    FormInputNumberComponent,
    FormInputSelectComponent,
    FormInputComponent,
    FormTextareaComponent,
    FormDatePickerComponent,
    FormDateRangePickerComponent,
    FormTimePickerComponent,
    FormSwitchComponent,
    FormSelectComponent,
    FormAsyncInputComponent,
    FormAsyncSelectComponent,
    FormInputAsyncSelectComponent,
    FormCheckboxComponent,
    HeaderTemplateDirective,
    SubHeaderTemplateDirective,
    BodyTemplateDirective,
    BodyHeaderTemplateDirective,
    RightSideTemplateDirective,
    FooterTemplateDirective,
    LayoutComponent,
    LinkToAngleOrStoryComponent,
    StandardGuidanceViewComponent,
    ElementDetailsViewComponent,
    ReportableApproverViewComponent,
    LicenseLimitationsViewComponent,
    LinkedAnglesViewComponent,
    LinkedStoriesViewComponent,
    MediaCentralDetailsViewComponent,
    LinkedStoriesComponent,
    LinkedAnglesComponent,
    LinkedStoryTeamComponent,
    UtcToLocalPipe,
    FileIcon,
    NBCIcon,
    StandardsGuidanceModalComponent,
    LimitedLicenseModalComponent,
    ReportableModalComponent,
    TextCommentsComponent,
    HTMLRichTextCommentsComponent,
    FollowStoryComponent,
    FollowGroupComponent,
    InfoCommentsComponent,
    InfoAttachmentsComponent,
    InfoShareComponent,
    InfoPinnedComponent,
    InfoTagsComponent,
    StoryComponent,
    GroupDiscussionComponent,
    LabelsComponent,
    StoryStatusComponent,
    MovePostModalComponent,
    DefaultOrderKeyvaluePipe,
    CustomSvgComponent,
    SessionBannerComponent,
    ScrollContainerComponent
  ],
  exports: [
    RteditorComponent,
    SlugComponent,
    TagsComponent,
    TagsNewComponent,
    AttachmentsComponent,
    AttachmentsNewComponent,
    GoogleDocsComponent,
    EmbedlyLinksComponent,
    AdditionalStoryComponent,
    ReportableComponent,
    ReportableNewComponent,
    ViewAttachmentsComponent,
    ViewThumbnailsComponent,
    LightBoxComponent,
    SharePostComponent,
    ShareDiscussionComponent,
    InfoCenterResearchComponent,
    PrivacyComponent,
    AdminComponent,
    ProfileOverlayComponent,
    AddMembersComponent,
    MoveDiscussionComponent,
    RenderAlertsComponent,
    LoaderComponentComponent,
    AddUsersComponent,
    RolesModalComponent,
    AddLabelsComponent,
    ByPassSecurityPipe,
    LimitedLicenseComponent,
    LimitedLicenseNewComponent,
    AdditionalTopicComponent,
    AdditionalTopicNewComponent,
    FormInputNumberComponent,
    FormInputSelectComponent,
    FormInputComponent,
    FormTextareaComponent,
    FormDatePickerComponent,
    FormDateRangePickerComponent,
    FormTimePickerComponent,
    FormSwitchComponent,
    FormSelectComponent,
    FormAsyncInputComponent,
    FormAsyncSelectComponent,
    FormInputAsyncSelectComponent,
    FormCheckboxComponent,
    HeaderTemplateDirective,
    SubHeaderTemplateDirective,
    BodyTemplateDirective,
    BodyHeaderTemplateDirective,
    RightSideTemplateDirective,
    FooterTemplateDirective,
    LayoutComponent,
    LinkToAngleOrStoryComponent,
    StandardGuidanceViewComponent,
    ElementDetailsViewComponent,
    ReportableApproverViewComponent,
    LicenseLimitationsViewComponent,
    LinkedAnglesViewComponent,
    LinkedStoriesViewComponent,
    MediaCentralDetailsViewComponent,
    LinkedStoriesComponent,
    LinkedAnglesComponent,
    LinkedStoryTeamComponent,
    UtcToLocalPipe,
    FileIcon,
    NBCIcon,
    StandardsGuidanceModalComponent,
    LimitedLicenseModalComponent,
    ReportableModalComponent,
    TextCommentsComponent,
    HTMLRichTextCommentsComponent,
    FollowStoryComponent,
    FollowGroupComponent,
    InfoCommentsComponent,
    InfoAttachmentsComponent,
    InfoShareComponent,
    InfoPinnedComponent,
    InfoTagsComponent,
    StoryComponent,
    GroupDiscussionComponent,
    LabelsComponent,
    StoryStatusComponent,
    MovePostModalComponent,
    DefaultOrderKeyvaluePipe,
    CustomSvgComponent,
    SessionBannerComponent,
    ScrollContainerComponent
  ],
  providers: [
    AppSingletonService,
    UtilityService,
    ViewVideoThumbnailsComponent,
    FormGroupDirective
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})

export class SharedModule {


  // Should be called once inside the app module
  public static forRoot(): ModuleWithProviders<SharedModule> {

    return {
      ngModule: SharedModule,
      providers: [
        AppSingletonService,
        UtilityService
      ]
    };
  
  }

}
